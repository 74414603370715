import React from "react";
import logo from "./images/drawing-logo.jpg";
import "./App.css";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InstagramIcon from "@material-ui/icons/Instagram";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import AppleIcon from "@material-ui/icons/Apple";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  createMuiTheme,
  ThemeProvider,
  styled,
} from "@material-ui/core/styles";

const font = "'Permanent Marker',";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#8dd1f3",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      default: "#000000",
    },
  },
  typography: {
    fontFamily: font,
  },
});

const TreeLinkButton = styled(Button)({
  background: "#8dd1f3",
  borderRadius: 5,
  border: 0,
  color: "white",
  padding: "30px 0px",
  margin: "10px",
  minHeight: "10vh",
  width: "60vmin",
  "&:hover": {
    backgroundColor: "#FFFFFF",
    color: "#8dd1f3",
  },
  fontSize: "16px",
  fontFamily: "Permanent Marker",
});

const postcardSubject = "I%20Want%20To%20Send%20A%20Postcard!";
const postcardBody =
  "Enter%20your%20name%20here:%0A%0AEnter%20recipient's%20name%20here:%0A%0AEnter%20recipient's%20address%20here:%0A%0AEnter%20your%20message%20to%20send%20to%20them%20here:%0A%0AChoose%20your%20which%20postcard%20you%20would%20like:";
const postcardTo = "marriedundertheinfluence@gmail.com";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12} align="center">
          <img src={logo} className="App-logo" alt="logo" />
        </Grid>
        <Grid item xs={12} align="center">
          <div className="MUI-Text">Married Under the Influence</div>
        </Grid>
        <Grid item xs={12} align="center">
          <TreeLinkButton href="https://podcasts.apple.com/us/podcast/married-under-the-influence/id1494255680">
            <AppleIcon className="Icon" /> Listen to Podcast
          </TreeLinkButton>
        </Grid>
        <Grid item xs={12} align="center">
          <TreeLinkButton
            href={
              "mailto:" +
              postcardTo +
              "?Subject=" +
              postcardSubject +
              "&body=" +
              postcardBody
            }
          >
            <MailOutlineIcon className="Icon" /> Send a Postcard
          </TreeLinkButton>
        </Grid>
        <Grid item xs={12} align="center">
          <TreeLinkButton href="https://instagram.com/marriedundertheinfluence?igshid=4ug6zgrw7f6l">
            <InstagramIcon className="Icon" /> Instagram
          </TreeLinkButton>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default App;
